<template>
    <div class="shouCang">
        <h1>已收藏的题目</h1>
        <van-tabs v-model="active">
            <van-tab v-for="(item,n) in tabsList" :key="n" :name="item" :title="item">
                <div class="item-con" v-for="(item,num) in this_label" :key="num" @click="goToLink(item)">
                    <div class="label-list">
                        <span class="sp" v-for="(list,i) in item.label.split(',')" :key="i" v-if="list">{{list}}</span>
                    </div>
                    <div class="ti-list">
                        <template v-for="(item,n) in item.exam">
                            <span :key="n" v-if="item[0]=='str'" class="item">{{item[1]}}</span>
                            <span :key="n" v-else-if="item[0]=='tex'" class="tex_item" :id="'tex_item_'+num+'_'+n+'_'+tabsList.indexOf(active)" v-html="item[1]"></span>
                        </template>
                    </div>
                </div>
            </van-tab>
        </van-tabs>
    </div>
</template>

<script>
    var $ = require( "jquery" );
    export default {
        data() {
            return {
                tabsList: ['最近收藏','自定义标签','概念定义','计算能力','性质运用','蒙对的'],
                active: '最近收藏',
                this_label: [],

                navList: [],
            }
        },
        watch: {
            active(newValue, oldValue) {
                this.shoucang_query_agg(newValue);
            }
        },
        methods: {
            user_info_write(dir, exam_idx, name){
                let book = this.book;
                let param = {
                    zhuanti: dir,
                    book: book,
                    exam_idx: exam_idx||0,
                    chapter: name
                }
                this.$api.user_info_write({param,method:'post'},res=>{
                    console.log('user_info_write-exam_idx-111111', res);
                    this.$router.push({path:'/topic', query:{name: name, book: book}});
                });
            },
            goToLink(item){
                let navList = this.navList;
                navList.map(e=>{
                    e.childs.map(e1=>{
                        e1.childs.map(e2=>{
                            let dir = e2.dir;
                            dir = dir.split('/');
                            dir = dir[dir.length-1];
                            if(dir === item.zhuanti){
                                console.log('zhuanti->', e1.name, item.exam_idx, e.name, e, e1);
                                this.user_info_write(e1.name, item.exam_idx, e.name);
                            }
                        });
                    })
                })
            },
            schedule_query(){
                this.$api.gaoshushang({},res=>{
                    console.log('r-e-s->', res);
                    this.navList = res.list;
                });
            },
            shoucang_query_agg(label) {
                // http://1.15.69.92:56728/shoucang_query_agg?book=gaoshushang&label=最近收藏
                let param = {
                    book: this.book,
                    label
                }
                this.$api.shoucang_query_agg({param,method:'post'}).then(res=>{
                    console.log('asa',res);
                    this.this_label = [];

                    this.$nextTick().then(e=>{

                        res.this_label.map((el,i)=>{
                            el.exam.map((e,n)=>{
                                if(e[0]=='tex'){
                                    let html = this.$kx.renderToString(e[1], {
                                        throwOnError: false
                                    });
                                    // console.log(html);
                                    e[1] = html;
                                    // let active = this.active;
                                    // let tabsList = this.tabsList;
                                    // let id = 'tex_item_'+i+'_'+n+'_'+tabsList.indexOf(active);

                                    // console.log(id);
                                    // let dom = document.getElementById(id);
                                    // console.log('dom', dom);
                                    // console.log('html', html);

                                    // // dom.innerHTML = html;
                                    // $('#'+id).html(html);
                                    // this.$kx.render(e[1], dom, {
                                    //     displayMode: true,
                                    // });
                                }
                                return e;
                            });
                            return el;
                        });
                        this.this_label = res.this_label;
                    })
                })
            }
        },
        created () {
            this.book = this.$route.query.book;
            this.schedule_query();
            this.shoucang_query_agg(this.tabsList[0]);
        },
    }
</script>

<style lang="less" scoped>
.shouCang{
    background: #fff;
    h1{
        height: 24px;
        font-size: 18px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #2A3155;
        line-height: 24px;
        padding: 16px;
        padding-bottom: 0;
        text-align: left;
    }
    .item-con{
        width: 351px;
        margin: 10px auto;
        mkin-height: 200px;
        background: #FFFFFF;
        box-shadow: 0px 0px 4px 0px rgba(42, 49, 85, 0.1);
        border-radius: 4px;
        border: 1px solid #E6E8EF;
        box-sizing: border-box;
        padding: 16px 12px;
        overflow: auto;
        .label-list{
            text-align: left;
            .sp{
                padding: 4px 8px;
                font-size: 12px;
                font-family: MicrosoftYaHei;
                color: #FFFFFF;
                line-height: 16px;
                background: #7792FE;
                border-radius: 2px;
                margin: 4px;
                display: inline-block;
            }
        }
        .ti-list{
            text-align: left;
            padding-top: 10px;
        }
    }
}
</style>
